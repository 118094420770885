
import router from "@/router";
import { defineComponent, reactive, ref, Ref } from "vue";
import { Field, Form } from "vee-validate";
import InputText from "primevue/inputtext";
import Button from "primevue/button";
import $API from "@/services";
import {
  IAlert,
  IResponseData,
  ResultDatas,
  RouterName,
} from "@/models/common";
import { messageType } from "@/models/message";
import { useStore } from "@/store";
import { CommonMutationTypes } from "@/store/modules/common/mutations";
import { AxiosResponse } from "axios";

export default defineComponent({
  name: "Reset Passwored",
  setup() {
    const data = reactive({
      email: "",
      password: "",
      passwordConfirm: "",
    });

    const state = reactive({
      code: router.currentRoute.value.query?.code,
    });

    const alert: Ref<IAlert> = ref({
      type: messageType.ERROR,
      content: "",
    });

    const store = useStore();

    return { state, data, alert, store };
  },
  methods: {
    clearMessage() {
      this.$router.push("/");
    },
    async resetPassword() {
      try {
        this.store.commit(CommonMutationTypes.SET_IS_LODING, true);
        const code = this.state.code as string;
        if (!code) {
          this.alert = {
            content: "Error",
            type: messageType.ERROR,
          };
          return;
        }
        const res: AxiosResponse<IResponseData<string>> =
          await $API.SignService.resetPassword(
            this.data.email,
            this.data.password,
            code
          );
        const {
          data: { ResultData },
        } = res;
        this.store.commit(CommonMutationTypes.SET_IS_LODING, false);
        if (Array.isArray(ResultData)) return;
        if (ResultData === ResultDatas.SUCCESS) {
          this.alert = {
            title: this.data.email,
            content: "패스워드가 변경되었습니다.",
            type: messageType.INFO,
            callback: () => {
              router.push(RouterName.SIGNIN);
            },
          };
        } else if (ResultData === "Token is invalid") {
          this.alert = {
            title: "패스워드 변경",
            content: "패스워드가 이미 변경되었거나 변경 기간이 만료되었습니다.",
            type: messageType.ERROR,
          };
        }
      } catch (e) {
        console.error(e);
        this.store.commit(CommonMutationTypes.SET_IS_LODING, false);
      }
    },
  },
  components: {
    Field,
    Form,
    InputText,
    Button,
  },
});
